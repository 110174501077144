import Link from 'next/link';
import Like from '../../_app/Like';
import Sharing from "../../_app/Sharing";
import {useSelector} from "react-redux";

const Item = ({el, api, mob}) => {
    const mobile = useSelector(state => state.mobile);
    return (

      <div className={el.img ? "news-feed__item fade-in" : "news-feed__item news-feed__item--no-image fade-in"}>
        <Link href={(el.url ? el.url : `/articles/${el.code}`)} key={el.id}>
          <a>

              {el.img && (mobile ? <div className="news-feed__item__img" style={{backgroundImage: `url(${api}${el.img})`}}/> : <div className="news-feed__item__img lazzy" data-image={`${api}${el.img}`}/>)}

            {/*el.img && <div className="news-feed__item__img lazzy" data-image={`${api}${el.img}`}></div>*/}
            {!el.img && <div className="news-feed__item__img news-feed__item__img_default" style={{backgroundImage: `url('/static/img/news-feed/default.png')`}}></div>}
          </a>
        </Link>
        <div className="news-feed__item__body">
          <div className="news-feed__item__type" dangerouslySetInnerHTML={{__html: el.section }} />
          <Link href={(el.url ? el.url : `/articles/${el.code}`)} key={el.id}>
            <a>
              <div className="news-feed__item__title" dangerouslySetInnerHTML={{__html: el.title }} />
            </a>
          </Link>
          <div className="news-feed__item__desc" dangerouslySetInnerHTML={{__html: el.dsc }}/>
          <div className="news-feed__item__info">
              <span className="news-feed__item__date">{el.date}</span>
              <div className="news-feed__item__likes">
                  <Like value={el.like} id={el.id} target={typeof window === 'object' ? (location.origin+(el.url ? el.url : `/articles/${el.code}`)) : ''} />
                  <Sharing target={typeof window === 'object' ? (location.origin+(el.url ? el.url : `/articles/${el.code}`)) : ''}/>
              </div>
          </div>
        </div>
      </div>
  )
}

export default Item;
