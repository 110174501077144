import { useState, useEffect } from 'react';
import axios from 'axios';

const Like = ({value, id, target}) => {

    const [count, setCount] = useState(value || 0);
    //const [disn, setDisn] = useState(dis ? dis : typeof sessionStorage!='undefined' && sessionStorage.getItem(item.id) ? 'disabled' : '');
    const [disn, setDisn] = useState('');

    useEffect(() => {
        setDisn(typeof sessionStorage!='undefined' && sessionStorage.getItem(id) ? 'disabled' : '');
        setCount(typeof sessionStorage!='undefined' && sessionStorage.getItem(id) ? sessionStorage.getItem(id) : value);
    },[])



    const change = () => {
        const fd = new FormData();
        fd.append('id', id);
        if(typeof ym != 'undefined' && target){
            ym(4831804, 'reachGoal', 'click_on_like', {URL: target});
        }
        if(typeof gtag != 'undefined' && target){
            gtag('event', 'select_click_', {'event_category': 'select_click'});
        }

        axios
            .post('/ajax/json.php?page=like', fd)
            .then(({ data }) => data)
            .then(response => {
                if(response.count){
                    setCount(response.count);
                    setDisn('disabled');
                    sessionStorage.setItem(id, response.count);
                }
            })
            .catch(error => {})
    }

    return(
        <span className={`like-icon news-feed__item__like ico-26 ${disn}`} onClick={change}>{count}</span>
    )
}

export default Like;
