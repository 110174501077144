import Head from 'next/head';

const HeadMeta = ({data, api}) => {
  if(!data) return null;

  return (
    <Head>
      {data.title && <title>{data.title}</title>}
      {data.description && <meta name="description" content={data.description} />}
{/*
      {data.keywords && <meta name="Keywords" content={Array.isArray(data.keywords) ? data.keywords.join(', ') : data.keywords} />}
*/}
      {data.noindex && <meta name="robots" content="noindex, follow"/>}
      {data.canonical && <link rel="canonical" href={data.canonical}/>}

      {data.title && <meta property="og:title" content={data.title}/>}
      {data.description && <meta property="og:description" content={data.description}/>}
      {data.canonical && <meta property="og:url" content={data.canonical} />}

    </Head>
  )
}

export default HeadMeta;
