import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router';
import axios from 'axios';
import Filter from './filter';
import Item from './Item';
import Actions from './Actions'
import News from './News'
import Slider from 'react-slick'
import _ from 'lodash';
import Link from 'next/link'
import Like from '../../_app/Like';
import Sharing from "../../_app/Sharing";

const EventsNew = ({api, filterDefault, home, type, no_but_more, articles = null, moreUrl = null}) => {
    const router = useRouter();
    const mobile = useSelector(state => state.mobile);
    const [activeList, setActiveList] = useState(filterDefault || []);
    const [pg, setPg] = useState(1);
    const [response, setResponse] = useState(articles)
    const [enable, setEnable] = useState(true);

    useEffect(() => {
        setPg(1)
        let cleanupFunction = false;

        if (articles) {
            if(!cleanupFunction){
                const articlesFiltered = articles.articles.items.filter(item => !item.double);
                if(articlesFiltered.length === 0) {
                    const element1 = document.querySelector('.appartment-by-id__header-bottom-menu div[data-key="events"]')
                    const element2 = document.querySelector('.appartment-menu-fixed__menu-item[data-key="events"]')
                    if(element1){
                        element1.remove();
                    }
                    if(element2){
                        element2.remove();
                    }
                };
            }
        } else {
            let params = {
                filter: activeList,
                PAGEN_1: 1
            }
            if(router.query.slug){
                params = {
                    filter: [],
                    PAGEN_1: 1,
                }

                if(router.query.slug[1]){
                    params.object = router.query.slug[1];
                }

                if(router.query.slug[2]){
                    params.flat = router.query.slug[2];
                }
            }
            axios
                .get(api + '/ajax/json.php?page=events', { params })
                .then(({ data }) => data)
                .then(response => {
                    if(response){
                        if(!cleanupFunction){
                            setResponse(response)

                            const articlesFiltered = response.articles.items.filter(item => !item.double);
                            if(articlesFiltered.length === 0) {
                                const element1 = document.querySelector('.appartment-by-id__header-bottom-menu div[data-key="events"]')
                                const element2 = document.querySelector('.appartment-menu-fixed__menu-item[data-key="events"]')
                                if(element1){
                                    element1.remove();
                                }
                                if(element2){
                                    element2.remove();
                                }
                            };
                        }
                    }
                })
        }

        return () => cleanupFunction = true;
    },[activeList]);

    const setActive = id => {
        const tmp = [...activeList];

        const index = activeList.indexOf(id);

        if(index === -1){
            tmp.push(id);
        }
        else{
            tmp.splice(index, 1);
        }

        setPg(1);
        setActiveList(tmp);
    }

    const settings = {
        arrows: false,
        dots: true,
        adaptiveHeight: true,
        lazyLoad: true,

    }

    const more = () => {
        if(enable){
            setEnable(false);
            let p = pg;
            p += 1;
            if(p > response.articles.pages){
                p = response.articles.pages;
            }

            let params = {
                filter: activeList,
                PAGEN_1: p
            }

            if(router.query.slug){
                params = {
                    filter: activeList,
                    PAGEN_1: p,
                }
                if(router.query.slug[1]){
                    if (moreUrl) {
                        params.code = router.query.slug[1];
                    } else {
                        params.object = router.query.slug[1];
                    }
                }
                if(router.query.slug[2]){
                    if (moreUrl) {
                        params.id = router.query.slug[2];
                    } else {
                        params.flat = router.query.slug[2];
                    }
                }
            }
            axios
                .get(api + (moreUrl || '/ajax/json.php?page=events'), { params })
                .then(({ data }) => data)
                .then(res => {
                    const articles = moreUrl ? res.data.find(el => el.type === 'articles').articles : res.articles;
                    const tmp = [...response.articles.items, ...articles.items]
                    const tmpResponse = {...response};
                    tmpResponse.articles.items = tmp;
                    setResponse(tmpResponse);
                    setPg(p);
                    setEnable(true);
                    window.scrollTo(window.scrollX, window.scrollY - 1);
                    window.scrollTo(window.scrollX, window.scrollY + 1);

                })
        }
    }

    if (!response) return null;

    const articlesFiltered = response.articles.items.filter(item => !item.double);
    if(articlesFiltered.length === 0) {
        return null
    };

    if (mobile) return (
        <div className="news-feed frame mobile-new-events">
            <div className="title">Лента  событий</div>
            {home && (
                <>
                    <div className="news-feed__gray frame">
                        <News data={response.main_articles} />
                        <Actions data={response.actions} />
                    </div>
                    <Filter types={response.filter} activeList={activeList} setActive={setActive} clear={() => setActiveList([])} />
                </>
            )}
            <div className="news-feed__list">
                <div className="news-feed__column">
                    <Slider {...settings}>{response.articles.items.map((el, i) => <Item api={api} key={i} el={el} mob={true} />)}</Slider>
                </div>
            </div>

            <Link href="/journal">
                <a className="btn btn--border news-feed__load-more no-border-link "><span>Наш журнал</span></a>
            </Link>
        </div>
    )

    const createTale = (data, pos) => {
        const first = [];
        const last = [];
        const all = [];

        data.forEach((item, index) => {
            if (typeof item === 'object') {
                all.push(<Item api={api} key={item.id} el={item}/>)

                //console.log(index);
                if(index === 0){
                    first.push(<Item api={api} key={item.id} el={item}/>)
                }
                if(index === 1){
                    last.push(<Item api={api} key={item.id} el={item}/>)
                }
            }
        });

        if(type==='more') {
            return all
        }

        if(pos % 1){
            return (
                <div className="events-tale__box first">
                    <div className="events-tale__box-in">{last}</div>
                    <div className="events-tale__box-in">{first}</div>
                </div>
            )
        }

        return (
            <div className="events-tale__box last">
                <div className="events-tale__box-in">{first}</div>
                <div className="events-tale__box-in">{last}</div>
            </div>
        )
    }

    const createDouble = index => {
        const data = response.articles.items.filter(item => item.double);
        if(data[index]){
            return(
                <div key={`item-double${index}`} className={data[index].img ? 'events-tale__double' : 'events-tale__double no-img'}>
                    {data[index].img && (
                        <div className="lazzy" data-image={data[index].img}></div>
                    )}
                    {!data[index].img && (
                        <div>
                            <img src="/static/img/news-feed/default.svg" />
                        </div>
                    )}
                    <div>
                        <div />
                        <div className="events-tale__double-wrap">
                            <div className="events-tale__double-title">{data[index].title}</div>
                            {data[index].dsc && <div className="events-tale__double-description" dangerouslySetInnerHTML={{ __html: data[index].dsc}} />}
                        </div>
                        <div>
                            <div className="news-feed__item__info">
                                <div className="news-feed__item__likes">
                                    <Like value={data[index].like} id={data[index].id} />
                                    <Sharing />
                                </div>
                                <span className="news-feed__item__date">{data[index].date}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    let c_d = 4;
    if(home){
        c_d = 3;
        articlesFiltered.length = 5;
    }
    //console.log('222222', articlesFiltered);

    if(type==='more'){
        c_d = 3;
    }
    const data = _.chunk(articlesFiltered, c_d);

    if(data.length === 1){
        if(data[0].length <= 3){

            if(home){
                return (
                    <div className="news-feed frame news-feed-tales" data-size={data[0].length}>
                        <div className="title">Лента событий</div>
                        {home && <Filter types={response.filter} activeList={activeList} setActive={setActive} clear={() => setActiveList([])} />}
                        <div className="events-tale">
                            <div className="events-tale__column">
                                {home && (
                                    <div className="news-feed__item">
                                        <div className="news-feed__slider">
                                            <News data={response.main_articles} />
                                            <Actions data={response.actions} />
                                        </div>
                                    </div>
                                )}
                                {data[0][2] && <Item api={api} key={data[0][2].id} el={data[0][2]}/>}
                            </div>
                            <div className="events-tale__column">
                                <div className="events-tale__box first">
                                    <div className="events-tale__box-in">{data[0][0] && <Item api={api} key={data[0][0].id} el={data[0][0]}/>}</div>
                                    <div className="events-tale__box-in">{data[0][1] && <Item api={api} key={data[0][1].id} el={data[0][1]}/>}</div>
                                </div>
                                <div>
                                    {createDouble(0)}
                                </div>
                            </div>
                        </div>
                        <Link href="/journal">
                            <a className="btn btn--border news-feed__load-more no-border-link "><span>Наш журнал</span></a>
                        </Link>
                    </div>
                )
            }

            return (
                <div className="news-feed frame news-feed-tales" data-size={data[0].length}>
                    <div className="title">Лента событий</div>
                    {home && <Filter types={response.filter} activeList={activeList} setActive={setActive} clear={() => setActiveList([])} />}
                    <div className="events-tale">
                        <div className="events-tale__column">
                            {home && (
                                <div className="news-feed__item">
                                    <div className="news-feed__slider">
                                        <News data={response.main_articles} />
                                        <Actions data={response.actions} />
                                    </div>
                                </div>
                            )}
                            {data[0][0] && <Item api={api} key={data[0][0].id} el={data[0][0]}/>}
                        </div>
                        <div className="events-tale__column">
                            <div className="events-tale__box first">
                                <div className="events-tale__box-in">{data[0][1] && <Item api={api} key={data[0][1].id} el={data[0][1]}/>}</div>
                                <div className="events-tale__box-in">{data[0][2] && <Item api={api} key={data[0][2].id} el={data[0][2]}/>}</div>
                            </div>
                            <div>
                                {createDouble(0)}
                            </div>
                        </div>
                    </div>
                    <Link href="/journal">
                        <a className="btn btn--border news-feed__load-more no-border-link "><span>Наш журнал</span></a>
                    </Link>

                </div>
            )
        }
    }

    return (
        <div className="news-feed frame news-feed-tales animate">
            <div className="title">Лента событий</div>
            {home && <Filter types={response.filter} activeList={activeList} setActive={setActive} clear={() => setActiveList([])} />}
            {home ?
                <div className="events-tale">
                    <div className="events-tale__column">
                        {home && (
                            <div className="news-feed__item">
                                <div className="news-feed__slider">
                                    <News data={response.main_articles} />
                                    <Actions data={response.actions} />
                                </div>
                            </div>
                        )}
                        {data.map((item, index) => {
                            if(item.length === (home ? 3 : 4)){
                                return <Item api={api} key={item[item.length - 1].id + index} el={item[item.length - 1]}/>
                            }
                        })}
                    </div>

                    <div className="events-tale__column">
                        {data.map((item, index) => (
                            <div key={`item${index}`}>
                                {createTale(item, index)}
                                {home && createDouble(index)}
                            </div>
                        ))}
                    </div>
                </div>
                :
                <div className="events-tale">
                    <div className="lenta_tale">
                        {articlesFiltered.map((item, index) => (
                            <Item api={api} key={index} el={item}/>
                        ))}
                    </div>
                </div>
            }

            {/*
      {type!=='more' && <Link href="/journal">
        <a className="btn btn--border news-feed__load-more no-border-link "><span>Показать еще6666</span></a>
      </Link>}*/}
            {/*pg < response.articles.pages && <div className="btn btn--border news-feed__load-more no-border-link" onClick={more}><span>Показать еще</span></div>*/}

            {!no_but_more && (type==='more1' ? (pg < response.articles.pages && <div className="btn btn--border news-feed__load-more no-border-link" onClick={more}><span>Показать еще</span></div>) : <Link href="/journal"><a className="btn btn--border news-feed__load-more no-border-link "><span>Показать еще</span></a></Link>)}
        </div>
    )
}

export default EventsNew;
