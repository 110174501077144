
const Filter = ({types, activeList, setActive, clear}) => {
  if (!types) return null
  return (
    <>
      <div className="news-feed__filter">
        {types.map((item, index) => (
          <div
            key={`item${index}`}
            className="news-feed__filter__item"
            onClick={() => setActive(item.code)}
            active={`${activeList.includes(item.code)}`}
          >
            <span>{item.title}</span>
          </div>
        ))}
      </div>
      <div className="news-feed__clear border-link" onClick={clear}><span>Сбросить всё</span></div>
    </>
  )
}

export default Filter;
