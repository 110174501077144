import { SvgShare, SvgTelegram, SvgInstagram, SvgVk, SvgOk, SvgFb, SvgWhatsApp, SvgViber, SvgEmail } from '../../icons';

const Sharing = ({link, target}) => {

  const share = type => {

    const url = link ? link : window.location.href

    if(type === 'vk'){
      if(typeof gtag != 'undefined' && target){
        gtag('event', 'share_vk_click', {'event_category': 'share_vk_click'});
      }
      if(typeof ym != 'undefined' && target){
        ym(4831804, 'reachGoal', 'click_share_vk', {URL: target});
      }
      window.open(`https://vk.com/share.php?url=${url}`, '_blank');
    }

    if(type === 'fb'){
      if(typeof gtag != 'undefined' && target){
        gtag('event', 'share_FB_click', {'event_category': 'share_FB_click'});
      }
      if(typeof ym != 'undefined' && target){
        ym(4831804, 'reachGoal', 'click_share_FB', {URL: target});
      }
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
    }

    if(type === 'tl'){
      //window.open(`tg://msg?text=${url}`);
      if(typeof gtag != 'undefined' && target){
        gtag('event', 'share_telegram_click', {'event_category': 'share_telegram_click'});
      }
      if(typeof ym != 'undefined' && target){
        ym(4831804, 'reachGoal', 'click_share_telegram', {URL: target});
      }
      window.open(`https://telegram.me/share/url?url=${url}&text=${url}`)
    }

    if(type === 'ok'){
      if(typeof gtag != 'undefined' && target){
        gtag('event', 'share_odnoknassniki_click', {'event_category': 'share_odnoknassniki_click'});
      }
      if(typeof ym != 'undefined' && target){
        ym(4831804, 'reachGoal', 'click_share_odnoknassniki', {URL: target});
      }
      window.open(`https://connect.ok.ru/offer?url=${url}`, '_blank');
    }


    if(type === 'wt'){
      if(typeof gtag != 'undefined' && target){
        gtag('event', 'share_whatsapp_click', {'event_category': 'share_whatsapp_click'});
      }
      if(typeof ym != 'undefined' && target){
        ym(4831804, 'reachGoal', 'click_share_whatsapp', {URL: target});
      }
      window.open(`https://api.whatsapp.com/send?text=${url}`);
    }

    if(type === 'vb'){

      if(typeof gtag != 'undefined' && target){
        gtag('event', 'share_viber_click', {'event_category': 'share_viber_click'});
      }
      if(typeof ym != 'undefined' && target){
        ym(4831804, 'reachGoal', 'click_share_viber', {URL: target});
      }
      window.open(`viber://forward?text=${url}`);
    }


    if(type === 'ml'){
      if(typeof gtag != 'undefined' && target){
        gtag('event', 'share_mail_click', {'event_category': 'share_mail_click'});
      }
      if(typeof ym != 'undefined' && target){
        ym(4831804, 'reachGoal', 'click_share_mail', {URL: target});
      }
      window.open(`mailto:?body=${url}`);
    }
  }

  return(
    <div className="sharing-icons">
      <SvgShare />

      <div className="sharing-icons__in">
        <div data-id="1" onClick={() => share('vk')}><SvgVk /></div>
        {/*<div data-id="2" onClick={() => share('fb')}><SvgFb /></div>*/}
        <div data-id="3" onClick={() => share('tl')}><SvgTelegram /></div>
        {/*<div data-id="4" onClick={() => share('in')}><SvgInstagram /></div>*/}
        <div data-id="5" onClick={() => share('ok')}><SvgOk /></div>
        {/*<div data-id="6" onClick={() => share('wt')}><SvgWhatsApp /></div>*/}
        <div data-id="7" onClick={() => share('vb')}><SvgViber /></div>
        <div data-id="8" onClick={() => share('ml')}><SvgEmail /></div>
      </div>
    </div>
  )
}

export default Sharing;
