import { useSelector } from 'react-redux';
import Link from '../../_app/link'
import Slider from 'react-slick'

const News = ({data}) => {

  const mobile = useSelector(state => state.mobile);

  const item = (el) => {
      //const style = {backgroundImage: `url(${el.img ? el.img : '/static/img/news-feed/default.png'})`}
      const style = `background-image: url(${el.img ? el.img : '/static/img/news-feed/default.png'})`;
    return (
      <div>
        <Link key={el.id} className={`news-feed__slider__item ${el.img ? '' : ' news-feed__slider__item--no-image'}`} to={(el.url ? el.url : `/articles/${el.code}`)}>
          <span className="news-feed__slider__item__img" data-style={style} />
          <div><span className="news-feed__slider__item__title" dangerouslySetInnerHTML={{__html: el.title}} /></div>
        </Link>
      </div>
    )
  }
  const items = data.map((el, i) => {
    if (i%4 === 0) {
      const el1 = data[i+1]
      const el2 = data[i+2]
      const el3 = data[i+3]

      return [
        <div key={el.id}>
          {item(el)}
          {el1 && item(el1)}
          {el2 && item(el2)}
          {el3 && item(el3)}
        </div>
      ]
    }
    return null
  })


  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    swipe: mobile,
  }

  return (
    <>
      <div className="news-feed__slider__title">Главное</div>
      <div className="news-feed__slider__body"><Slider {...settings}>{items}</Slider></div>
    </>
  )
}


export default News;
